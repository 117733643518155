import {
  Alert,
  Button,
  Container,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Facebook, Instagram, Language, YouTube } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  getOpenMicSignups,
  removePerformer,
  sendPerformerEmails,
  updatePerformerStatus,
} from "../../services/firebase";

const TikTokIcon = ({ color = "action" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20px"
      height="20px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const SocialMediaCell = ({ signup }) => {
  const getSocialUrl = (platform, handle) => {
    const urls = {
      facebook: `https://facebook.com/${handle}`,
      instagram: `https://instagram.com/${handle.replace("@", "")}`,
      tiktok: `https://tiktok.com/@${handle.replace("@", "")}`,
      youtube: handle.includes("http")
        ? handle
        : `https://youtube.com/${handle}`,
      other: handle.includes("http") ? handle : `https://${handle}`,
    };
    return urls[platform];
  };

  return (
    <div className="space-y-2">
      <div className="text-sm font-medium text-gray-900">{signup.email}</div>

      {signup.facebook && (
        <p>
          <a
            href={getSocialUrl("facebook", signup.facebook)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600 transition-colors"
          >
            <Facebook className="w-4 h-4" />
            <span>{signup.facebook}</span>
          </a>
        </p>
      )}

      {signup.instagram && (
        <p>
          <a
            href={getSocialUrl("instagram", signup.instagram)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-pink-600 transition-colors"
          >
            <Instagram className="w-4 h-4" />
            <span>{signup.instagram}</span>
          </a>
        </p>
      )}

      {signup.tiktok && (
        <p>
          <a
            href={getSocialUrl("tiktok", signup.tiktok)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-black transition-colors"
          >
            <TikTokIcon className="w-4 h-4" />
            <span>{signup.tiktok}</span>
          </a>
        </p>
      )}

      {signup.youtube && (
        <p>
          <a
            href={getSocialUrl("youtube", signup.youtube)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-red-600 transition-colors"
          >
            <YouTube className="w-4 h-4" />
            <span>{signup.youtube}</span>
          </a>
        </p>
      )}

      {signup.otherSocial && (
        <p>
          <a
            href={getSocialUrl("other", signup.otherSocial)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
          >
            <Language className="w-4 h-4" />
            <span>{signup.otherSocial}</span>
          </a>
        </p>
      )}
    </div>
  );
};

const OpenMicAdmin = () => {
  const [signups, setSignups] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    const fetchSignups = async () => {
      const data = await getOpenMicSignups();
      data.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setSignups(data);
    };
    fetchSignups();
  }, []);

  const handleStatusChange = async (id, selected) => {
    await updatePerformerStatus(id, selected);
    setSignups(
      signups.map((signup) =>
        signup.id === id ? { ...signup, selected } : signup
      )
    );
  };
  const handleRemove = async (id) => {
    await removePerformer(id);
    setSignups(signups.filter((signup) => signup.id !== id));
  };

  const handleSendEmails = async () => {
    try {
      const emailsSent = await sendPerformerEmails();
      console.log({ emailsSent });
      setSnackbar({
        open: true,
        message: "Emails sent successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send emails",
        severity: "error",
      });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Open Mic Admin
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSendEmails}>
        Send Emails to Performers
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Stage Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Sign Up Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signups.map((signup, index) => (
              <TableRow key={signup.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{signup.name}</TableCell>
                <TableCell>{signup.stageName}</TableCell>
                <TableCell>
                  <SocialMediaCell signup={signup} />
                </TableCell>
                <TableCell>
                  {signup.createdAt.toDate().toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Button
                    fullWidth
                    variant={signup.selected ? "contained" : "outlined"}
                    onClick={() =>
                      handleStatusChange(signup.id, !signup.selected)
                    }
                  >
                    {signup.selected ? "✅ Selected" : "Select"}
                  </Button>
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to remove this performer?"
                        )
                      ) {
                        handleRemove(signup.id);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default OpenMicAdmin;
