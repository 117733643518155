import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { auth, updateProfile } from "../services/firebase";
import { enforceFormat, formatToPhone } from "../services/utils";

import { AppContext } from "../context/AppContext";
import { CartContext } from "../context/CartContext";
import { Waiver } from "../components/Waiver";
import { useNavigate } from "react-router-dom";

const steps = ["Basic Info", "Contact Details", "Membership Agreement"];

export const MemberOnboarding = () => {
  const navigate = useNavigate();
  const { member, memberPasses } = useContext(AppContext);
  const { addDayPassToCart } = useContext(CartContext);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const currentUser = auth.currentUser;
  const providerData = currentUser?.providerData?.[0] || {};

  const [formData, setFormData] = useState({
    displayName:
      member?.displayName ||
      currentUser?.displayName ||
      providerData?.displayName ||
      "",
    email: member?.email || currentUser?.email || providerData?.email || "",
    phoneNumber:
      member?.phoneNumber ||
      currentUser?.phoneNumber ||
      providerData?.phoneNumber ||
      "",
    agreeToWaiver: false,
  });

  useEffect(() => {
    // If user already completed onboarding, redirect to home
    if (member?.onboardingComplete) {
      navigate("/");
      return;
    }

    if (formData.phoneNumber) {
      const input = formData.phoneNumber.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        setFormData((prev) => ({
          ...prev,
          phoneNumber: `(${areaCode}) ${middle}-${last}`,
        }));
      } else if (input.length > 3) {
        setFormData((prev) => ({
          ...prev,
          phoneNumber: `(${areaCode}) ${middle}`,
        }));
      } else if (input.length > 0) {
        setFormData((prev) => ({
          ...prev,
          phoneNumber: `(${areaCode}`,
        }));
      }
    }
  }, [member, navigate, formData.phoneNumber]);

  const validateStep = (step) => {
    const newErrors = {};

    switch (step) {
      case 0:
        if (!formData.displayName) {
          newErrors.displayName = "Name is required";
        }
        break;
      case 1:
        if (!formData.email) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Email is invalid";
        }
        break;
      case 2:
        if (!formData.agreeToWaiver) {
          newErrors.waiver = "You must agree to the membership waiver";
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!validateStep(activeStep)) {
      return;
    }

    if (activeStep === steps.length - 1) {
      // Final step - save everything
      setLoading(true);
      try {
        await updateProfile(member, {
          ...formData,
          onboardingComplete: true,
          signedReleaseWaiver: true,
        });

        // If user has no passes, add a day pass to cart and redirect to checkout
        if (!memberPasses || memberPasses.length === 0) {
          addDayPassToCart();
          navigate("/checkout");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        setErrors({ submit: "Failed to save profile. Please try again." });
      } finally {
        setLoading(false);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "agreeToWaiver" ? checked : value,
    }));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Welcome! Let's get to know you better.
            </Typography>
            <TextField
              fullWidth
              label="Full Name"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              error={!!errors.displayName}
              helperText={errors.displayName}
              margin="normal"
              required
            />
          </Box>
        );

      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              How can we reach you?
            </Typography>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              onKeyDown={enforceFormat}
              onKeyUp={formatToPhone}
              margin="normal"
            />
          </Box>
        );

      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Membership Agreement
            </Typography>
            <Paper
              elevation={3}
              sx={{ p: 2, maxHeight: "300px", overflow: "auto", mb: 2 }}
            >
              <Waiver />
            </Paper>
            <FormControlLabel
              control={
                <Checkbox
                  name="agreeToWaiver"
                  checked={formData.agreeToWaiver}
                  onChange={handleChange}
                />
              }
              label="I agree to the membership waiver"
            />
            {errors.waiver && (
              <Typography color="error" variant="body2">
                {errors.waiver}
              </Typography>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  if (!member) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        {errors.submit && (
          <Typography color="error" sx={{ mt: 2 }}>
            {errors.submit}
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button variant="contained" onClick={handleNext} disabled={loading}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};
