import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { db } from "../services/firebase";
import {
  doc,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import HostSchedule from "../components/HostSchedule";

const Host = () => {
  const { user } = useContext(AppContext);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [dailyBud, setDailyBud] = useState("");
  const [clockInTime, setClockInTime] = useState(null);

  useEffect(() => {
    if (user) {
      const hostRef = doc(db, "clubs", "cola-stl", "hosts", user.uid);
      const unsubscribe = onSnapshot(
        hostRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.isActive) {
              setIsClockedIn(data.isClockedIn || false);
              setDailyBud(data.dailyBud || "");
              setClockInTime(
                data.clockInTime ? data.clockInTime.toDate() : null
              );
            } else {
              // Handle case where host is not active
              console.log("Host is not active");
            }
          } else {
            // Handle case where host document doesn't exist
            console.log("Host document does not exist");
          }
        },
        (error) => {
          console.error("Error fetching host data:", error);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);

  const handleClockInOut = async () => {
    if (!user) return;

    const hostRef = doc(db, "clubs", "cola-stl", "hosts", user.uid);
    const now = Timestamp.now();

    if (isClockedIn) {
      // Clock out
      await updateDoc(hostRef, {
        isClockedIn: false,
        clockOuts: arrayUnion({
          time: now,
          dailyBud: dailyBud,
        }),
        dailyBud: "",
        clockInTime: null,
        lastClockOutTime: serverTimestamp(),
      });
    } else {
      // Clock in
      await updateDoc(hostRef, {
        isClockedIn: true,
        clockIns: arrayUnion(now),
        clockInTime: now,
        lastClockInTime: serverTimestamp(),
      });
    }
  };

  const handleDailyBudChange = async (event) => {
    const newDailyBud = event.target.value;
    setDailyBud(newDailyBud);

    if (user) {
      const hostRef = doc(db, "clubs", "cola-stl", "hosts", user.uid);
      await updateDoc(hostRef, {
        dailyBud: newDailyBud,
        lastUpdate: serverTimestamp(),
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Host Manager
      </Typography>

      <Button
        variant="contained"
        color={isClockedIn ? "secondary" : "primary"}
        onClick={handleClockInOut}
        sx={{ marginBottom: 2 }}
      >
        {isClockedIn ? "Clock Out" : "Clock In"}
      </Button>

      {isClockedIn && (
        <>
          <Typography variant="body1" gutterBottom>
            Clocked in at: {clockInTime?.toLocaleTimeString()}
          </Typography>

          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Daily Bud</InputLabel>
            <Select
              value={dailyBud}
              onChange={handleDailyBudChange}
              label="Daily Bud"
            >
              <MenuItem value="flower">Flower</MenuItem>
              <MenuItem value="dab">Dab</MenuItem>
              <MenuItem value="snack">Snack</MenuItem>
              <MenuItem value="ice cream">Ice Cream</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <HostSchedule />
    </Box>
  );
};

export default Host;
