import { CartContext, CartProvider } from "../context/CartContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppContext } from "../context/AppContext";
import { AppProvider } from "../context/AppContext";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import Paper from "@mui/material/Paper";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../services/theme";
import { useContext } from "react";

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getNumberOfItemsInCart } = useContext(CartContext);
  const { isAuthPending, member } = useContext(AppContext);
  const cartCount = getNumberOfItemsInCart();

  const isMember = !isAuthPending && member;

  const NavButton = ({ icon, label, path, isHome = false }) => (
    <Box
      onClick={() => navigate(path)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color:
          location.pathname === path
            ? "primary.contrastText"
            : "common.lightText",
        cursor: "pointer",
        transition: "all 0.3s",
        flex: 1,
        "&:hover": {
          color: "primary.contrastText",
        },
        ...(isHome && {
          mt: -4,
          mb: 1,
          position: "relative",
          zIndex: 2,
        }),
      }}
    >
      {icon}
      <Box
        component="span"
        sx={{
          fontSize: "0.75rem",
          mt: 0.5,
          textAlign: "center",
        }}
      >
        {label}
      </Box>
    </Box>
  );

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "primary.main",
        height: "4.5rem",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        pb: 1,
        px: 2,
        "&::before": {
          content: '""',
          position: "absolute",
          top: -20,
          left: "50%",
          transform: "translateX(-50%)",
          width: 70,
          height: 70,
          backgroundColor: "primary.main",
          borderRadius: "50%",
          zIndex: 1,
        },
      }}
    >
      <NavButton icon={<HomeIcon />} label="Home" path="/" />
      <NavButton icon={<EventIcon />} label="Events" path="/events" />
      <NavButton
        icon={
          <Box
            component="img"
            src="/cola-stl.svg"
            sx={{
              width: 50,
              height: 60,
              filter: "drop-shadow(0 0 4px rgba(255, 255, 255, 0.7))",
            }}
          />
        }
        label=""
        path="/"
        isHome={true}
      />
      {isMember ? (
        <NavButton
          icon={<AccountCircleIcon />}
          label="Profile"
          path="/profile"
        />
      ) : (
        <NavButton
          icon={<AccountCircleIcon />}
          label="Sign In"
          path="/signin"
        />
      )}
      <NavButton
        icon={
          <Badge badgeContent={cartCount} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        }
        label="Cart"
        path="/checkout"
      />
    </Paper>
  );
};

export const Layout = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <CartProvider>
          <CssBaseline />
          <Container
            maxWidth="md"
            sx={{
              pt: 2,
              px: 0,
              pb: 10,
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Outlet />
            </Box>
            <BottomNav />
          </Container>
        </CartProvider>
      </AppProvider>
    </ThemeProvider>
  );
};

export default Layout;
