import React, { useState, useEffect } from "react";

import { Typography, List, ListItem, ListItemText } from "@mui/material";
import { getHostSchedule } from "../services/firebase";

const HostSchedule = () => {
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("fetching schedule");
    const fetchSchedule = async () => {
      try {
        const result = await getHostSchedule();
        console.log({ result });
        if (result.data) {
          setSchedule(result.data);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching host schedule:", err);
        setError("Failed to load schedule");
        setLoading(false);
      }
    };

    fetchSchedule();
  }, []);

  if (loading) return <Typography>Loading schedule...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Upcoming Host Schedule
      </Typography>
      <List>
        {schedule.map((event, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={event.summary}
              secondary={`${new Date(
                event.start
              ).toLocaleString()} - ${new Date(
                event.end
              ).toLocaleTimeString()}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default HostSchedule;
