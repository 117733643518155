import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  Typography,
  Box,
  Paper,
  Link,
} from "@mui/material";
import RulesModal from "../components/RulesModal";
import { registerVendor } from "../services/firebase";

const validationSchema = Yup.object({
  eventName: Yup.string().required("Event Name is required"),
  eventDate: Yup.date().required("Event Date is required"),
  businessName: Yup.string().required("Business Name is required"),
  contactName: Yup.string().required("Contact Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  boothSize: Yup.string().required("Booth Size is required"),
  needsPower: Yup.boolean().required("Power requirement is required"),
  goodsServices: Yup.string().required(
    "Description of goods/services is required"
  ),
  agreeToRules: Yup.boolean().oneOf(
    [true],
    "You must agree to the rules and expectations"
  ),
});

const VendorForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);

  const handleRulesClick = (event) => {
    event.preventDefault();
    setRulesModalOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      eventName: "",
      eventDate: "",
      businessName: "",
      contactName: "",
      email: "",
      phoneNumber: "",
      website: "",
      boothSize: "",
      needsPower: false,
      goodsServices: "",
      specialRequests: "",
      agreedBoothFee: "",
      agreeToRules: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await registerVendor(values);
        if (result.success) {
          setSubmitSuccess(true);
        } else {
          console.error("Unexpected result from registerVendor:", result);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  if (submitSuccess) {
    return (
      <Paper elevation={3} sx={{ p: 3, mt: 13, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          Thank you for your application!
        </Typography>
        <Typography variant="body1">
          We have received your vendor application and will be in touch soon.
        </Typography>
      </Paper>
    );
  }

  const isActive = true;

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        St. Louis Cannabis Club Vendor Application
      </Typography>
      {isActive ? (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="eventName"
            name="eventName"
            label="Event Name"
            value={formik.values.eventName}
            onChange={formik.handleChange}
            error={formik.touched.eventName && Boolean(formik.errors.eventName)}
            helperText={formik.touched.eventName && formik.errors.eventName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="eventDate"
            name="eventDate"
            label="Event Date"
            type="date"
            value={formik.values.eventDate}
            onChange={formik.handleChange}
            error={formik.touched.eventDate && Boolean(formik.errors.eventDate)}
            helperText={formik.touched.eventDate && formik.errors.eventDate}
            margin="normal"
          />
          <TextField
            fullWidth
            id="businessName"
            name="businessName"
            label="Business Name"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={
              formik.touched.businessName && Boolean(formik.errors.businessName)
            }
            helperText={
              formik.touched.businessName && formik.errors.businessName
            }
            margin="normal"
          />
          <TextField
            fullWidth
            id="contactName"
            name="contactName"
            label="Contact Name"
            value={formik.values.contactName}
            onChange={formik.handleChange}
            error={
              formik.touched.contactName && Boolean(formik.errors.contactName)
            }
            helperText={formik.touched.contactName && formik.errors.contactName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            margin="normal"
          />
          <TextField
            fullWidth
            id="website"
            name="website"
            label="Website/Social Media (optional)"
            value={formik.values.website}
            onChange={formik.handleChange}
            margin="normal"
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Booth Size Required</FormLabel>
            <RadioGroup
              aria-label="boothSize"
              name="boothSize"
              value={formik.values.boothSize}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="6ft"
                control={<Radio />}
                label="6 ft table"
              />
              <FormControlLabel
                value="10x10"
                control={<Radio />}
                label="10x10 booth space"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.needsPower}
                onChange={formik.handleChange}
                name="needsPower"
              />
            }
            label="Do you need power for your booth?"
          />
          <TextField
            fullWidth
            id="goodsServices"
            name="goodsServices"
            label="Type of Goods/Services Offered"
            multiline
            rows={4}
            value={formik.values.goodsServices}
            onChange={formik.handleChange}
            error={
              formik.touched.goodsServices &&
              Boolean(formik.errors.goodsServices)
            }
            helperText={
              formik.touched.goodsServices && formik.errors.goodsServices
            }
            margin="normal"
          />
          <TextField
            fullWidth
            id="specialRequests"
            name="specialRequests"
            label="Special Setup Requests or Additional Requirements"
            multiline
            rows={4}
            value={formik.values.specialRequests}
            onChange={formik.handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            id="agreedBoothFee"
            name="agreedBoothFee"
            label="Agreed Booth Fee ($)"
            type="number"
            value={formik.values.agreedBoothFee}
            onChange={formik.handleChange}
            margin="normal"
          />
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.agreeToRules}
                  onChange={formik.handleChange}
                  name="agreeToRules"
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link href="#" onClick={handleRulesClick}>
                    rules, expectations, and assumption of risk
                  </Link>
                </span>
              }
            />
            {formik.touched.agreeToRules && formik.errors.agreeToRules && (
              <Typography color="error" variant="caption">
                {formik.errors.agreeToRules}
              </Typography>
            )}
          </Box>
          <Box mt={2}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit Application
            </Button>
          </Box>
        </form>
      ) : (
        <Typography variant="body1">
          Vendor applications are currently closed. Please check back later.
        </Typography>
      )}
      <RulesModal
        open={rulesModalOpen}
        onClose={() => setRulesModalOpen(false)}
      />
    </Paper>
  );
};

export default VendorForm;
