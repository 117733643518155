import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  }
`;

const EventImage = styled(CardMedia)`
  width: 100%;
  padding-top: 65%;
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
  background-color: rgba(0, 0, 0, 0.08);
`;

const EventDate = styled(Typography)`
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  border: 2px solid green;
  border-radius: 10px;
  margin: -60px 32px 0 auto;
  & > img {
    margin: 0;
  }
`;
const EventTitle = styled("h2")`
  fontSize: "3.5rem",
  position: "relative",
  backgroundColor: "#fff",
  borderRadius: "10px",
  fontWeight: "600",
  width: "fit-content",
  height: "fit-content",
  zIndex: 100,
`;

export const EventCard = ({ event }) => {
  const navigate = useNavigate();
  const navigateToEvent = ({ id }) => {
    navigate(`/events/${id}`);
  };
  const eventImage = event.mainImage?.src;
  return (
    <StyledCard onClick={() => navigateToEvent(event)}>
      <EventImage image={eventImage} alt={event.name} />
      <EventDate variant="h5" elevation={6} px={2}>
        {event.nextInstance
          ? `${new Date(
              event.nextInstance.startDate.seconds * 1000
            ).toLocaleDateString()} at ${new Date(
              event.nextInstance.startDate.seconds * 1000
            ).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`
          : "No upcoming dates"}
      </EventDate>

      <CardContent>
        <EventTitle px={1} py={2}>
          {event.name}
        </EventTitle>
        <Typography variant="body1" mt={1}>
          {event.summary}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          color="primary"
          onClick={() => navigateToEvent(event)}
        >
          Buy Tickets
        </Button>
      </CardActions>
    </StyledCard>
  );
};
