import firebase from "firebase/compat/app";
import { logEvent } from "../services/firebase";

function Error({ error, resetErrorBoundary }) {
  logEvent(firebase.analytics.EventName.EXCEPTION, {
    description: error.message,
    fatal: true,
  });

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default Error;
