import { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CartContext } from "../context/CartContext";
import { ProductOptions } from "./ProductOptions";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { centsToCurrency } from "../services/firebase";
import { useNavigate } from "react-router-dom";

export const ProductCard = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Added to Cart");

  const [selectedOptions, setSelectedOptions] = useState([]);

  const updateOption = (selected) => {
    setSelectedOptions([selected]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goToCheckout = () => {
    navigate("/checkout");
  };

  const handleAddToCart = (product) => {
    addToCart(product, selectedOptions);
    setMessage(`${product.name} added to cart`);
    setOpen(true);
  };

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardMedia
        image={product.image}
        alt={product.name}
        sx={{
          width: "100%",
          paddingBottom: "60%",
          clipPath: "polygon(0 0, 100% 0%, 100% 80%, 0% 100%)",
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        }}
      />
      <Typography
        gutterBottom
        variant="h5"
        elevation={3}
        sx={{
          px: 2,
          position: "relative",
          width: "fit-content",
          height: "fit-content",
          backgroundColor: "#fff",
          border: "2px solid green",
          borderRadius: "10px",
          margin: "-60px 32px 0 auto",
          "& > img": {
            margin: 0,
          },
        }}
      >
        {centsToCurrency(product.amount)}
      </Typography>
      <CardContent>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            px: 2,
            py: 1,
            width: "fit-content",
            height: "fit-content",
          }}
        >
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ProductOptions product={product} updateOption={updateOption} />
        <Button
          size="small"
          color="primary"
          onClick={() => handleAddToCart(product)}
        >
          Add to Cart
        </Button>
      </CardActions>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        key="topcenter"
        message={message}
        onClose={handleClose}
        open={open}
        action={
          <Button color="warning" size="small" onClick={goToCheckout}>
            Checkout
          </Button>
        }
      />
    </Card>
  );
};
