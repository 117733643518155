import { Box, Paper, Snackbar, Typography } from "@mui/material";
import { centsToCurrency, getOrder } from "../services/firebase";
import { timeToDateString, timeToTimeString } from "../services/utils";
import { useEffect, useState } from "react";

import { ClubLocation } from "../components/ClubLocation";
import { ClubMap } from "../components/ClubMap";
import { MemberPasses } from "../components/MemberPasses";
import { useParams } from "react-router-dom";

const ThankYouPage = () => {
  const { orderId } = useParams(); // Get orderId from URL parameters

  const [currentOrder, setCurrentOrder] = useState(null);
  const [orderHasPasses, setOrderHasPasses] = useState(false);
  const [orderHasTickets, setOrderHasTickets] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId).then((oc) => {
        if (oc) {
          setOrderHasPasses(oc.lineItems.some((item) => item.type === "pass"));
          setOrderHasTickets(
            oc.lineItems.some((item) => item.type === "ticket")
          );
          setCurrentOrder(oc);
        } else {
          console.error("Order not found");
          setError("Order not found");
        }
      });
    }
  }, [orderId]);

  if (!currentOrder && !error) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper sx={{ px: 8, py: 8 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={orderHasPasses}
        autoHideDuration={6000}
        message="Your passes are being generated..."
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={orderHasTickets}
        autoHideDuration={6000}
        message="Your tickets are being generated..."
      />
      <Typography variant="h5" gutterBottom>
        Thank you for your purchase!
      </Typography>
      <Typography variant="subtitle1" gutterBottom noWrap>
        <strong>Order ID:</strong> {orderId}
      </Typography>
      <Typography variant="subtitle1" gutterBottom noWrap>
        <strong>Date:</strong>{" "}
        {currentOrder.createdate.toDate().toLocaleDateString()} at{" "}
        {currentOrder.createdate.toDate().toLocaleTimeString()}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Order Summary
      </Typography>
      <Box sx={{ mb: 2, p: 2, boxShadow: 1 }}>
        {currentOrder.lineItems.map((lineItem) => (
          <Box
            key={lineItem.id}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1" gutterBottom>
              {lineItem.name}
              {lineItem.type === "ticket" && (
                <Typography variant="body2" color="textSecondary">
                  {timeToDateString(lineItem.startDate)} at{" "}
                  {timeToTimeString(lineItem.startDate)}
                </Typography>
              )}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {lineItem.quantity} x {lineItem.price}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {centsToCurrency(lineItem.quantity * lineItem.amount)}
            </Typography>
          </Box>
        ))}
        {currentOrder.discount && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              fontWeight: "bold",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Discount
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              -{centsToCurrency(currentOrder.discount.amountOff)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 2,
            fontWeight: "bold",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Total
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {centsToCurrency(currentOrder.amount)}
          </Typography>
        </Box>
        <Typography variant="body2">
          A receipt has been emailed to you.
        </Typography>
      </Box>

      <Paper
        sx={{
          maxHeight: "40vh",
          overflow: "auto",
          mb: 4,
          p: 2,
          boxShadow: 1,
        }}
      >
        <MemberPasses />
      </Paper>
      <Paper sx={{ mb: 4, p: 2, boxShadow: 1 }}>
        <ClubLocation />
        <ClubMap />
      </Paper>
    </Paper>
  );
};

export default ThankYouPage;
