import "firebase/compat/auth";

import { Box, Container, Typography } from "@mui/material";

// SignIn.js
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../services/firebase";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

const SignInScreen = () => {
  const navigate = useNavigate();

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        // Safely check if this is a new user
        const isNewUser = authResult?.additionalUserInfo?.isNewUser;

        if (isNewUser) {
          // New user - redirect to onboarding
          navigate("/onboarding");
          return false;
        }

        // Existing user - handle normal redirect
        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
        if (redirectAfterLogin) {
          localStorage.removeItem("redirectAfterLogin");
          navigate(redirectAfterLogin);
          return false;
        }

        navigate("/");
        return false;
      },
    },
    tosUrl: "/terms",
    privacyPolicyUrl: "/privacy",
  };

  return (
    <Container>
      <Box textAlign={"center"}>
        <img src="/cola-stl.svg" alt="club logo" style={{ height: "30vh" }} />
      </Box>
      <Typography variant="h6" align="center">
        Log In or Create an Account
      </Typography>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </Container>
  );
};

export default SignInScreen;
