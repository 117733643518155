import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";
import { AppContext } from "../context/AppContext";
import { CartContext } from "../context/CartContext";
import { getActiveEvent } from "../services/firebase";

export const Welcome = () => {
  const { user, memberPasses } = useContext(AppContext);
  const { addToCart } = useContext(CartContext);
  const [activeEvent, setActiveEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveEvent = async () => {
      const event = await getActiveEvent();
      setActiveEvent(event);
    };
    fetchActiveEvent();
  }, []);

  const handleSignIn = () => {
    navigate("/signin");
  };

  const handleCreateAccount = () => {
    navigate("/signin");
  };

  const handlePurchasePass = () => {
    navigate("/checkout");
  };

  const handleCheckIn = () => {
    navigate("/checked-in");
  };

  const handlePurchaseEventTicket = () => {
    if (activeEvent) {
      addToCart(activeEvent.tickets[0]); // Add first ticket type to cart
      navigate("/checkout");
    }
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcome to The Cola St. Louis</title>
      </Helmet>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to The Cola St. Louis
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          {!user ? (
            <>
              <Typography variant="h6" gutterBottom>
                Please sign in or create an account to continue
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignIn}
                fullWidth
                sx={{ mt: 2 }}
              >
                Sign In
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCreateAccount}
                fullWidth
                sx={{ mt: 2 }}
              >
                Create Account
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                Welcome back, {user.displayName}!
              </Typography>
              {memberPasses.length > 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCheckIn}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Check In with Your Pass
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePurchasePass}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Purchase a Pass
                </Button>
              )}
            </>
          )}
        </Paper>
        {activeEvent && (
          <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              Active Event: {activeEvent.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {activeEvent.description}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePurchaseEventTicket}
              fullWidth
              sx={{ mt: 2 }}
            >
              Purchase Event Ticket
            </Button>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default Welcome;
