import { Box, Tooltip, useTheme } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const SocialShare = ({
  url = window.location.href,
  title = "",
  description = "",
  hashtags = [],
  media = "",
  subject = "",
  body = "",
  iconSize = 32,
  platforms = [
    "facebook",
    "twitter",
    "linkedin",
    "pinterest",
    "whatsapp",
    "email",
  ],
  className = "",
  onShare,
}) => {
  const theme = useTheme();

  // Common configuration for share windows
  const windowConfig = {
    windowWidth: 800,
    windowHeight: 600,
  };

  // Handle successful share
  const handleShare = (platform) => {
    if (onShare) {
      onShare(platform);
    }
  };

  const getButtonConfigs = (theme) => ({
    facebook: {
      Component: FacebookShareButton,
      Icon: FacebookIcon,
      tooltip: "Share on Facebook",
      props: {
        url,
        quote: description,
        hashtag: hashtags.length > 0 ? `#${hashtags[0]}` : undefined,
        ...windowConfig,
        onClick: () => handleShare("facebook"),
      },
    },
    twitter: {
      Component: TwitterShareButton,
      Icon: TwitterIcon,
      tooltip: "Share on Twitter",
      props: {
        url,
        title: `${title} ${description}`.trim(),
        hashtags,
        ...windowConfig,
        onClick: () => handleShare("twitter"),
      },
    },
    linkedin: {
      Component: LinkedinShareButton,
      Icon: LinkedinIcon,
      tooltip: "Share on LinkedIn",
      props: {
        url,
        title,
        summary: description,
        source: window.location.hostname,
        ...windowConfig,
        onClick: () => handleShare("linkedin"),
      },
    },
    pinterest: {
      Component: PinterestShareButton,
      Icon: PinterestIcon,
      tooltip: "Share on Pinterest",
      props: {
        url,
        media,
        description,
        ...windowConfig,
        onClick: () => handleShare("pinterest"),
      },
    },
    whatsapp: {
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
      tooltip: "Share on WhatsApp",
      props: {
        url,
        title,
        separator: " - ",
        ...windowConfig,
        onClick: () => handleShare("whatsapp"),
      },
    },
    email: {
      Component: EmailShareButton,
      Icon: EmailIcon,
      tooltip: "Share via Email",
      props: {
        url,
        subject: subject || title,
        body: body || description,
        separator: " - ",
        ...windowConfig,
        onClick: () => handleShare("email"),
      },
    },
  });

  const buttonConfigs = getButtonConfigs(theme);

  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      {platforms.map((platform) => {
        const config = buttonConfigs[platform];
        if (!config) return null;

        const { Component, Icon, tooltip, props } = config;

        return (
          <Tooltip key={platform} title={tooltip}>
            <Box
              sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <Component {...props}>
                <Icon size={iconSize} round />
              </Component>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default SocialShare;
