import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const textRef = useRef(null);

  const maxLength = 58;

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setOverflowing(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    if (text.length > maxLength) {
      setOverflowing(true);
    } else {
      checkOverflow();
    }

    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [text, maxLength]);

  const handleToggle = () => {
    if (overflowing) {
      setExpanded(!expanded);
    }
  };

  return (
    <Box
      onClick={handleToggle}
      sx={{
        cursor: overflowing ? "pointer" : "default",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expanded ? "none" : 1,
        position: "relative",
        ":after": {
          content: '""',
          position: "absolute",
          top: "0",
          right: "0",
          bottom: expanded ? "auto" : "0",
          left: "0",
          pointerEvents: "none",
          display: expanded || !overflowing ? "none" : "block",
        },
      }}
    >
      <Typography variant="body2" ref={textRef} sx={{ whiteSpace: "normal" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ExpandableText;
