import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";

import { AppContext } from "../../context/AppContext";
import { CartContext } from "../../context/CartContext";
import { QuantitySelector } from "../QuantitySelector";
import { centsToCurrency } from "../../services/firebase";
import { useNavigate } from "react-router-dom";

export const DayPass = () => {
  const { passTemplates } = useContext(AppContext);
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);
  const navigate = useNavigate();

  const dayPass = passTemplates.find((pass) => pass.type === "day");

  if (!dayPass) {
    return null;
  }

  const getPrice = (item, quantity) => {
    if (!item.quantityDiscount) {
      return item.amount;
    }
    let price = item.amount;
    Object.keys(item.quantityDiscount).forEach((qty) => {
      if (quantity >= qty) {
        price = item.quantityDiscount[qty];
      }
    });
    return price;
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };

  const item = cartItems.find((item) => item.type === "day") || {
    ...dayPass,
    quantity: 0,
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, margin: "0 auto" }}>
      <CardHeader
        title="Save By Buying Multiple Passes"
        sx={{ textAlign: "center" }}
      />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>1+ Day Pass:</TableCell>
              <TableCell align="right">
                <Typography fontWeight={item.quantity < 5 ? "bold" : "normal"}>
                  {centsToCurrency(getPrice(dayPass, 1))} each
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5+ Day Passes:</TableCell>
              <TableCell align="right">
                <Typography
                  fontWeight={
                    item.quantity > 4 && item.quantity < 10 ? "bold" : "normal"
                  }
                >
                  {centsToCurrency(getPrice(dayPass, 5))} each
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10+ Day Passes:</TableCell>
              <TableCell align="right">
                <Typography
                  fontWeight={item.quantity >= 10 ? "bold" : "normal"}
                >
                  {centsToCurrency(getPrice(dayPass, 10))} each
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
        >
          <QuantitySelector
            item={item}
            add={addToCart}
            remove={removeFromCart}
          />
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            color="primary"
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </Box>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <Typography sx={{ fontSize: ".75em", textAlign: "center" }}>
          Good for any day of the week
        </Typography>
        <Typography sx={{ fontSize: ".75em", textAlign: "center" }}>
          No expiration until used
        </Typography>
        <Typography sx={{ fontSize: ".75em", textAlign: "center" }}>
          Share with friends and family
        </Typography>
      </Box>
    </Paper>
  );
};

export default DayPass;
