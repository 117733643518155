import { Box, Button, Container, Typography } from "@mui/material";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { auth, db } from "../services/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

const base_url = "https://us-central1-manageyourclub-973b2.cloudfunctions.net";
const location_id = "LSE0029KQMC9T";
const application_id = "sq0idp-Z9rpS42Bx5ZcL--MM4CJ_w";

export const requiresAuth = (cartItems) => {
  return cartItems.some(
    (item) =>
      item.type === "pass" ||
      item.type === "ticket" ||
      item.productType === "pass" ||
      item.productType === "ticket"
  );
};

export const PaymentProcessor = ({
  total,
  cartItems,
  member,
  guestInfo,
  clubId,
  discount,
  shippingAddress,
  onPaymentSuccess,
  onPaymentError,
}) => {
  const createPaymentRequest = () => {
    if (total <= 0) return null;

    return {
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: (total / 100).toString(),
        label: "Total",
      },
      lineItems: cartItems,
      requestBillingContact: true,
      requestShippingContact: false,
    };
  };

  const createGuestMember = async () => {
    try {
      // Create Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        guestInfo.email,
        // Generate a random password - user can reset it later via email
        Math.random().toString(36).slice(-8)
      );

      const user = userCredential.user;

      // Create member document
      const memberRef = doc(db, `clubs/${clubId}/members`, user.uid);
      await setDoc(memberRef, {
        id: user.uid,
        email: guestInfo.email,
        displayName: guestInfo.name,
        phoneNumber: guestInfo.phone || null,
        emailVerified: false,
        emailOptIn: false,
        waiverSigned: false,
        lastLogin: serverTimestamp(),
        createdate: serverTimestamp(),
        lastupdate: serverTimestamp(),
      });

      // Send email verification
      await sendEmailVerification(user);

      return user;
    } catch (error) {
      console.error("Error creating guest member:", error);
      throw error;
    }
  };

  const handlePayment = async (token) => {
    try {
      let userId = member?.id;

      // If guest info is provided, create a new member
      if (guestInfo && !member) {
        const newUser = await createGuestMember();
        userId = newUser.uid;
      }

      const response = await fetch(`${base_url}/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: userId,
          clubId,
          lineItems: cartItems,
          discount,
          total,
          currency: "USD",
          sourceId: token,
          shippingAddress: shippingAddress || null,
          customerInfo: guestInfo,
        }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      const order = await response.json();
      onPaymentSuccess(order);
    } catch (error) {
      onPaymentError(error.message);
    }
  };

  return (
    <PaymentForm
      applicationId={application_id}
      cardTokenizeResponseReceived={handlePayment}
      createPaymentRequest={createPaymentRequest}
      locationId={location_id}
    >
      <Container sx={{ my: 4 }}>
        {total <= 0 ? (
          <>
            <Typography variant="h5" color="error" gutterBottom>
              Your total is $0.00. No payment is required.
            </Typography>
            <Button
              onClick={() => handlePayment({ token: "FREE" })}
              variant="contained"
              color="primary"
            >
              Complete Order
            </Button>
          </>
        ) : (
          <CreditCard
            buttonProps={{
              css: {
                backgroundColor: "#2c7e3f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#569765",
                },
              },
            }}
          />
        )}
        <Box sx={{ my: 2 }} />
      </Container>
    </PaymentForm>
  );
};
