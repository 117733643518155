import { Helmet } from "react-helmet";
import React from "react";

const SocialMetaTags = ({
  title = "The Cola Lounge - St. Louis, MO",
  description = "The Cola Lounge is the premier cannabis consumption lounge in St. Louis, MO. It's Where Bud's Meet!",
  image = "/cola-stl.svg",
  page = "",
  siteName = "The Cola Lounge",
}) => {
  const url = `https://ColaLounge.com/${page}`;
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={siteName} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default SocialMetaTags;
