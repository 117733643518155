import React, { useContext } from "react";

import { AppContext } from "../../context/AppContext";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CartContext } from "../../context/CartContext";
import { CheckInButton } from "../CheckInButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import HostManager from "../host/Manager";

const StyledCard = styled(Card)({
  borderRadius: "1rem",
  boxShadow: "none",
  position: "relative",
  minWidth: 200,
  minHeight: 360,
  "&:after": {
    content: '""',
    display: "block",
    position: "absolute",
    width: "100%",
    height: "64%",
    bottom: 0,
    zIndex: 1,
    background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
  },
});

const StyledCardMedia = styled(CardMedia)({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: "top",
});

const Content = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "absolute",
  zIndex: 2,
  top: 0,
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const Title = styled("h1")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: "1rem",
  fontSize: "3rem",
  letterSpacing: "1px",
  fontWeight: "bold",
  textTransform: "initial",
  marginBottom: 0,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const Subtitle = styled("h2")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: "1rem",
  fontSize: "1.5rem",
  fontWeight: "bold",
  lineHeight: 1.2,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down("sm")]: {
    fontSize: ".85rem",
  },
}));

const ButtonArea = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "absolute",
  zIndex: 3,
  bottom: 0,
  width: "100%",
  textAlign: "right",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderRadius: "1rem",
  border: "1px solid #fff",
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.common.fadeToBlack,
  fontSize: "1.75rem",
  lineHeight: 1.2,
  transition: "all 0.5s ease",
  "&:hover": {
    fontSize: "2rem",
    fontWeight: "bold",
  },
}));

const heroImage =
  "https://firebasestorage.googleapis.com/v0/b/manageyourclub-973b2.appspot.com/o/clubs%2Fcola-stl%2Fheader%2Fheader-your-spot.jpg?alt=media&token=59c86b8e-e6a1-47cd-ab9e-bd051dc6de29";

export function Header() {
  const { passTemplates, member, isCheckedIn, memberPasses } =
    useContext(AppContext);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const dayPass = passTemplates.find((pass) => pass.type === "day");
  const memberName = member?.displayName || "Guest";

  const addPassAndCheckout = () => {
    addToCart(dayPass);
    navigate("/checkout");
  };

  // if (member && member.isHost) {
  //   return <HostManager />;
  // }

  if (isCheckedIn()) {
    return (
      <StyledCard>
        <StyledCardMedia image={heroImage} />
        <Content>
          <Title>Welcome Back, {memberName}</Title>
          <Subtitle>Are you happy and high?</Subtitle>
        </Content>
      </StyledCard>
    );
  }

  if (memberPasses && memberPasses.length > 0) {
    const activePass = memberPasses.find((pass) => pass.status === "active");
    const pendingPass = memberPasses.find((pass) => pass.status === "pending");
    const availablePass = activePass || pendingPass;

    if (availablePass) {
      return (
        <StyledCard>
          <StyledCardMedia image={heroImage} />
          <Content>
            <Title>Welcome Back, {memberName}</Title>
            <Subtitle>Check In and Enjoy Your Visit!</Subtitle>
          </Content>
          <ButtonArea>
            <CheckInButton pass={availablePass} />
          </ButtonArea>
        </StyledCard>
      );
    }
  }

  return (
    <StyledCard>
      <StyledCardMedia image={heroImage} />
      <Content>
        <Title>Find Your Spot</Title>
        <Subtitle>Where Buds Meet</Subtitle>
      </Content>
      <ButtonArea>
        <StyledButton onClick={addPassAndCheckout}>Get A Day Pass</StyledButton>
      </ButtonArea>
    </StyledCard>
  );
}
