import { Box, Button, Collapse, Snackbar, TextField } from "@mui/material";
import React, { useContext, useState } from "react";

import { CartContext } from "../context/CartContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getDiscount } from "../services/firebase";

export const DiscountCode = () => {
  const { setDiscount, discountCode } = useContext(CartContext);
  const [inputValue, setInputValue] = useState(discountCode);
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const applyCode = async () => {
    setError(null);
    if (!inputValue) {
      setError("Please enter a discount code");
      return;
    }
    const validDiscount = await getDiscount(inputValue);
    if (validDiscount.error) {
      setError("Invalid Discount Code");
      return;
    }
    setDiscount(validDiscount);
    setSnackbarOpen(true);
    setShowInput(false);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ textAlign: "right" }}>
      <Button
        onClick={() => setShowInput(!showInput)}
        sx={{ mt: 1 }}
        startIcon={
          showInput ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
        }
      >
        {inputValue ? `Change Discount Code` : `Have A Discount Code?`}
      </Button>
      <Collapse in={showInput}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            applyCode();
          }}
        >
          {error && <Box sx={{ color: "red" }}>{error}</Box>}
          <Box sx={{ px: 2, mt: 1, display: "flex", spacing: 1 }}>
            <TextField
              sx={{ mr: 2 }}
              fullWidth
              name="discountCode"
              label="Enter Your Discount Code"
              variant="outlined"
              value={inputValue}
              onChange={(event) => {
                setInputValue(event.target.value);
                if (event.key === "Enter") {
                  event.preventDefault();
                  applyCode();
                }
              }}
            />
            <Button
              disabled={!inputValue}
              variant="contained"
              onClick={applyCode}
            >
              Apply
            </Button>
          </Box>
        </form>
      </Collapse>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Discount code applied!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};
