import { Box, Button, TextField } from "@mui/material";

import { useState } from "react";

export const GuestCheckout = ({ onSubmit }) => {
  const [guestInfo, setGuestInfo] = useState({
    email: "",
    name: "",
    phone: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(guestInfo);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <TextField
        required
        fullWidth
        label="Name"
        value={guestInfo.name}
        onChange={(e) => setGuestInfo({ ...guestInfo, name: e.target.value })}
        margin="normal"
      />
      <TextField
        required
        fullWidth
        type="email"
        label="Email"
        value={guestInfo.email}
        onChange={(e) => setGuestInfo({ ...guestInfo, email: e.target.value })}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Phone (optional)"
        value={guestInfo.phone}
        onChange={(e) => setGuestInfo({ ...guestInfo, phone: e.target.value })}
        margin="normal"
      />
      <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
        Continue to Payment
      </Button>
    </Box>
  );
};
